import React from 'react';
import Landing from './pages/Landing';



const App = () => {
 
  return (
    <div>
    <Landing />
    </div>
  );
}

export default App;

// import React,{useState} from 'react'
// import { Button} from "semantic-ui-react";
// import {Radio} from 'react-bootstrap';
// import Qs from "qs";
// // import axios from 'axios';

// // import logo from '../../logo.svg';


// function App() {
//   const [formData, setformData] = useState("");
  
//   const onChange = e =>{
//     // console.log("Key:" +e.target.name);
//     // console.log("Value:" +e.target.value);

//     setformData({
//       ...formData,
//       [e.target.name]: e.target.value
//     });
//   }
  
//   const onSubmit =(e)=> {
//     console.log(formData);
//   //   console.log("Formulario enviado");
//   //   // toast.success("The information was send succesfully");
//   //   axios.post(`http://localhost:8080/`,  formData)
//   // //   axios.post(`https://nodejs-notifications-293520.ue.r.appspot.com`,  formData)
//   //   // axios.post(`https://docs.google.com/forms/d/e/1FAIpQLScFu_1DcBBZYVrPrFZgZRil3iDig7dUGC_C567Rb-IpDjY9yw/formResponse`, { formData })
//   //   .then(res => {
//   //     console.log(res);
//   //     console.log(res.data);
//   //   })
// //   var urlencoded = new URLSearchParams();
// //   urlencoded.append("entry.1082118604", "exito");
// //   urlencoded.append("entry.593854367", "Reactjs");
// //   urlencoded.append("entry.460552442", "Personal");
// //   urlencoded.append("emailAddress", "comligospanish@gmail.com");
// // console.log(urlencoded);

// var str = Qs.stringify(formData)


//  e.preventDefault();
//   fetch('https://docs.google.com/forms/u/0/d/e/1FAIpQLSfxVfTGyEXyG6_98hMZDrGXm84l0Xfv3khJSklnezCQy6LBHA/formResponse', {
//    method: 'post',
//    headers: {'Content-Type':'application/x-www-form-urlencoded'},
//    body: str,
//    redirect: 'follow'
//   });
//   e.target.reset();
// }  
//   return (
//     <>
//  <div className="App">
//  <header className="App-header">
//  <form onSubmit={onSubmit} onChange={onChange}>
//     <fieldset>
//         <h2>U1TEST<br/><small></small></h2>
//     </fieldset>
//     <fieldset>
//         <legend for="">Email</legend>
//         <div class="form-group">
//             <input id="emailAddress" type="email" name="emailAddress" class="form-control" required/>
//         </div>
//     </fieldset>



//     <fieldset>
//         <legend for="834557304">Ayer jugamos __ fútbol.</legend>
//         <Radio className="r11" name="groupOptions" name="entry.1904485212" value="a" required>a</Radio>
//         <Radio className="r12" name="groupOptions" name="entry.1904485212" value="de" required>de</Radio>
//         <Radio className="r13" name="groupOptions" name="entry.1904485212" value="hacer" required>hacer</Radio>
//         {/* <div class="form-group">
//             <div class="radio">
//                 <label>
//                     <input type="radio" name="entry.1904485212" value="a" required/>
//                     a
//                 </label>
//             </div>
//             <div class="radio">
//                 <label>
//                     <input type="radio" name="entry.1904485212" value="de" required/>
//                     de
//                 </label>
//             </div>
//             <div class="radio">
//                 <label>
//                     <input type="radio" name="entry.1904485212" value="hacer" required/>
//                     hacer
//                 </label>
//             </div>
//         </div> */}
//     </fieldset>


 
//     {/* <fieldset>
//         <legend for="2107673404"></legend>
//         <div class="form-group">
//             <div class="radio">
//                 <label>
//                     <input type="radio" name="entry.1885618088" value="Вариант 1" />
//                     Вариант 1
//                 </label>
//             </div>
//         </div>
//     </fieldset> */}


   
//     <fieldset>
//         <legend for="1183512286">El sábado __ al cine</legend>
//         <Radio className="r14" name="groupOptions" name="entry.1189992621" value="íbamos" required>íbamos</Radio> 
//         <Radio className="r15" name="groupOptions" name="entry.1189992621" value="vamos" required>vamos</Radio> 
//         <Radio className="r16" name="groupOptions" name="entry.1189992621" value="fuimos" required>fuimos</Radio> 
//         {/* <div class="form-group">
//             <div class="radio">
//                 <label>
//                     <input type="radio" name="entry.1189992621" value="íbamos" required/>
//                     íbamos
//                 </label>
//             </div>
//             <div class="radio">
//                 <label>
//                     <input type="radio" name="entry.1189992621" value="vamos" required/>
//                     vamos
//                 </label>
//             </div>
//             <div class="radio">
//                 <label>
//                     <input type="radio" name="entry.1189992621" value="fuimos" required/>
//                     fuimos
//                 </label>
//             </div>
//         </div> */}
//     </fieldset>


//     <fieldset>
//         <legend for="1043292423">Ya lo he leído. </legend>
//         <Radio className="r17" name="groupOptions" name="entry.1285827406" value="el libro" required>el libro</Radio> 
//         <Radio className="r18" name="groupOptions" name="entry.1285827406" value="la revista" required>la revista</Radio> 
//         <Radio className="r19" name="groupOptions" name="entry.1285827406" value="los libros" required>los libros</Radio> 
//         {/* <div class="form-group">
//             <div class="radio">
//                 <label>
//                     <input type="radio" name="entry.1285827406" value="el libro" required/>
//                     el libro
//                 </label>
//             </div>
//             <div class="radio">
//                 <label>
//                     <input type="radio" name="entry.1285827406" value="la revista" required/>
//                     la revista
//                 </label>
//             </div>
//             <div class="radio">
//                 <label>
//                     <input type="radio" name="entry.1285827406" value="los libros" required/>
//                     los libros
//                 </label>
//             </div>
//         </div> */}
//     </fieldset>


 
//     <fieldset>
//         <legend for="931265129">De pequeño, cada verano __ al pueblo de vacaciones.</legend>
//         <Radio className="r20" name="groupOptions" name="entry.1601160703" value="fuimos" required>fuimos</Radio> 
//         <Radio className="r21" name="groupOptions" name="entry.1601160703" value="hemos ido" required>hemos ido</Radio> 
//         <Radio className="r22" name="groupOptions" name="entry.1601160703" value="íbamos" required>íbamos</Radio> 
//         {/* <div class="form-group">
//             <div class="radio">
//                 <label>
//                     <input type="radio" name="entry.1601160703" value="fuimos" required/>
//                     fuimos
//                 </label>
//             </div>
//             <div class="radio">
//                 <label>
//                     <input type="radio" name="entry.1601160703" value="hemos ido" required/>
//                     hemos ido
//                 </label>
//             </div>
//             <div class="radio">
//                 <label>
//                     <input type="radio" name="entry.1601160703" value="íbamos" required/>
//                     íbamos
//                 </label>
//             </div>
//         </div> */}
//     </fieldset>


   
//     <fieldset>
//         <legend for="954193019">¿Qué has hecho este fin de semana?</legend>
//         <br></br>
//         <div class="form-group">
//             <textarea id="423955567" name="entry.423955567" class="form-control" ></textarea>
//         </div>
//     </fieldset>

//     <input type="hidden" name="fvv" value="1"/>
//     <input type="hidden" name="fbzx" value="4594603693232347946"/>
 
//     <input type="hidden" name="pageHistory" value="0"/>

//     <Button className="btn-primary" type="submit">Send</Button>
// </form>
// </header>
// </div>
//     </>
//   )
// }
// export default  App;