// import {} from 'react-bootstrap';
import {Button,Input,Grid,GridColumn,Image,Form} from 'semantic-ui-react';
import React,{useState} from 'react'
// import axios from 'axios';
import { toast } from "react-toastify";
import Qs from "qs";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './Landing.scss';

function Formulario () {
  const [formData, setformData] = useState("");
  // const onChange = e =>{
  //   setformData({
  //     ...formData,
  //     [e.target.name]: e.target.value
  //   });
  // }
  const HOST = process.env.HOST || "http://localhost:8080"
  //  const HOST = process.env.HOST || "https://restserver-323001.uw.r.appspot.com"
  
  const onSubmit =(e)=> {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(formData);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(HOST+"/api/webpage/landing-portugues", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
      e.target.reset();
    //   var str = Qs.stringify(formData)
    //   e.preventDefault();
    //   fetch('https://docs.google.com/forms/d/e/1FAIpQLSdT-nreiEx3WhLPKmv0zaucoI-yFORvTmA9W_ksHrtL3iQWuA/formResponse', {
    //   method: 'post',
    //   headers: {'Content-Type':'application/x-www-form-urlencoded'},
    //   mode:'no-cors',
    //   body: str,
    //   redirect: 'follow'
    // });
    // e.target.reset();
    // toast.success("Your answers were sent");
  };

  return(
 
    <div class="contenedor"> 
    <header className = "App-header-landing">
    <div className = "landing-container">
    <Grid container columns={2}>
    <Grid.Column width={12}>
    <img src = {'https://firebasestorage.googleapis.com/v0/b/landingcomligo.appspot.com/o/ImagenesLandingComligo%2Flogin%20page%20logo.png?alt=media&token=3a2c1909-73d6-4eb3-8943-216c84ae71ba'} className = "logotipo"></img>       
    </Grid.Column>
  </Grid>
    <Grid stackable columns={2}> 
    <Grid.Column  width={2}>
    </Grid.Column>
    <Grid.Column  width={6}>
    <div className="izq">

    <Form onSubmit={onSubmit} >
                  <fieldset>                  
                      <p className="conocer-titulo"><h1>Inscreva-se já em qualquer um dos nossos cursos on-line!</h1></p>
                      <p className="detalles">Preencha seus dados abaixo</p>
                      <br></br>
                      <br></br>
                        <input
                          type = "text"                         
                          placeholder = "Primeiro Nome*"
                          className="tres"
                          onChange={e => setformData({...formData, firstname: e.target.value})}
                        />
                        <br></br>
                        <br></br>
                        <br></br>
                        <input type = "text" 
                        placeholder = "Último sobrenome*" required 
                        className="tres"
                        onChange={e => setformData({...formData, lastname: e.target.value})}
                        />
                        <br></br>
                        <br></br>
                        <br></br>
                        <input type = "text" 
                        placeholder = "Cupom da empresa"
                        className="tres"
                        onChange={e => setformData({...formData, company: e.target.value})}
                        />
                        <br></br>
                        <br></br>
                        <br></br>
                         <input type = "email" 
                         placeholder = "Email*" 
                         required
                         className="tres"
                         onChange={e => setformData({...formData, email: e.target.value})}
                         />
                          <br></br>
                        <br></br>
                        <br></br>
                         <input type = "text" 
                          placeholder = "Telefone*" required
                          className="tres"
                          onChange={e => setformData({...formData, phone: e.target.value})}
                          />
                         <br></br>
                        <br></br>
                        <br></br>
                         <input type = "text" 
                          placeholder = "Em que curso de espanhol você está interessado?" required
                          className="tres"
                          onChange={e => setformData({...formData, course: e.target.value})}
                          />
  
                  </fieldset>
                  <Button type = "submit" className = "landing-envio">MANDAR</Button>
                </Form>               
     </div>
    </Grid.Column>
    <Grid.Column  width={6} >
     <div className="der">

     <ul className="listado-landing">
                    <li>
                       <p className = "parr-uno">
                       Ensinamos espanhol, somente espanhol, através de uma plataforma única, projetada por linguistas renomados                       </p>                                        
                    </li>
                    <li>
                       <p className = "parr-dos">
                       Nossos exclusivos cursos  vão desde o espanhol geral até espanhol para negócios ou para outros fins específicos, todos seguindo diretrizes internacionais  (CEFR e ACTFL)                       </p>
                    </li>
                    <li>
                       <p className = "parr-tres">
                       Temos o cuidado especial de incluir elementos interculturais em TODOS os nossos cursos                       </p>
                    </li>
                    <li>
                       <p className = "parr-cuatro">
                       Nossa equipe de professores está formada por profissionais altamente qualificados e nativos de diferentes países de língua espanhola                       </p>
                    </li>
                  </ul>        

   
                </div> 
    </Grid.Column>
    <Grid.Column  width={4}>
    </Grid.Column>
  </Grid>
    </div>
  </header>
  </div> 

  );
  
}

export default Formulario;